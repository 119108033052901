import request from '@/utils/request';
import { AUDIENCE_BASE_URL, BASEURL } from '@/utils/request';
import axios from 'axios';
import store from '@/store';
// 自定义受众列表
export function listCustomerAudience(data) {
  return request({
    url: '/ads/listCustomerAudience',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 受众规模
export function deliveryEstimateAndSentence(data) {
  return request({
    url: '/ads/deliveryEstimateAndSentence',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

// 类似受众来源
export function lookalikeSources(data) {
  return request({
    url: '/ads/lookalikeSources',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}
// 创建受众
export function createCustomaudience(data) {
  return request({
    url: '/ads/createCustomaudience',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}
// 分享受众
// ads/shareCustomAudience
export function shareCustomAudience(data) {
  return request({
    url: '/ads/shareCustomAudience',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 创建品类受众
export function createSaleAudience(data) {
  return request({
    url: '/audience/createSaleAudience',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 创建类似受众
export function createLookalikeAudience(data) {
  return request({
    url: '/ads/createLookalikeAudience',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 创建共享受众
export function createShare(data) {
  return request({
    url: '/audience/createShare',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 修改共享受众
export function updateShare(data) {
  return request({
    url: '/audience/updateShare',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 把共享受众添加到广告账号
export function shareAddToAccounts(data) {
  return request({
    url: '/audience/shareAddToAccounts',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 查询共享受众
export function sharedAudiences(data) {
  return request({
    url: '/audience/sharedAudiences',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

// 下载客户名单示例文件
export let downloadExcel = BASEURL + '/static/file/template/创建受众模板.xlsx';
// 创建客户名单受众
export function createCustomNameAudience(data) {
  return request({
    url: '/audience/createCustomAudience',
    method: 'post',
    data: data,
    timeout: 0,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 查询组织机构需要用到网关
const POST = (
  url,
  data,
  config = {
    baseURL: process.env.VUE_APP_AUDIENCE_BASE,
  },
) => {
  console.log(process.env);
  return request({
    method: 'post',
    url: url,
    data,
    headers: {
      'Content-Type': 'application/json',
      _token: store.getters.token,
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
    ...config,
  });
};
// export const gimpQuery = (data) => request({
//     method:'post',
//     url:"/ads-gimp/native/query",
//     data:data,
//     baseURL: process.env.VUE_AUDIENCE_BASE
//   })
// 查询gimp数据
export const gimpQuery = (data) => POST('/native/query', data);

// 查询像素
export function accountPixel(params) {
  return request({
    url: '/ads/accountPixel',
    method: 'get',
    params: {
      ...params,
    },
  });
}
